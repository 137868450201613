import logo from "../../utils/logo.png";
import colors from "../../utils/colors";
import img from "../../utils/footerBackround.png";

const Footer = () => {
    const footerStyle = {
        backgroundColor: colors.secondary,
        color: colors.text,
        backgroundImage: `url(${img})`,
    };

    return (
        <footer className="py-6 shadow-4xl border-1" style={footerStyle}>
            <div className="container px-6 mx-auto space-y-6 divide-y md:space-y-12 divide-opacity-50">
                <div className="grid grid-cols-12">
                    <div className="pb-6 col-span-full md:pb-0 md:col-span-6">
                        <a
                            rel="noopener noreferrer"
                            href="#"
                            className="flex justify-center space-x-3 md:justify-start"
                        >
                            <div className="flex items-center justify-center w-12 h-12 rounded-full">
                                <img src={logo} alt=""></img>
                            </div>
                            <span
                                className="self-center text-2xl text-[#000]"
                                style={{ fontWeight: "500" }}
                            >
                                EuroOne
                            </span>
                        </a>
                    </div>
                    <div className="col-span-6 text-center md:text-left md:col-span-3">
                        <p
                            className="pb-1 text-lg text-[#000000]"
                            style={{ fontWeight: "400" }}
                        >
                            Page Links
                        </p>
                        <ul>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    href="home"
                                    className="hover:text-[#176b87]"
                                >
                                    Home
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    href="ppcompressionfittings"
                                    className="hover:text-[#176b87]"
                                >
                                    Products
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    href="contactus"
                                    className="hover:text-[#176b87]"
                                >
                                    Contact us
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    href="gallery"
                                    className="hover:text-[#176b87]"
                                >
                                    Gallery
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-6 text-center md:text-left md:col-span-3">
                        <a
                            href="contactus"
                            className="pb-1 text-lg text-[#000000]"
                            style={{ fontWeight: "400" }}
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
                <div className="grid justify-center pt-6 lg:justify-between">
                    <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                        <span>©2024 All rights reserved</span>
                        <a rel="noopener noreferrer" href="#">
                            <span>Privacy policy</span>
                        </a>

                        <a
                            rel="noopener noreferrer"
                            href="https://portfolio-git-main-hamzaahmad6292s-projects.vercel.app/"
                            className="text-[#176b87] hover:text-[#0a4a5d] ml-4"
                            style={{ fontWeight: "400" }}
                        >
                            Made by H@mza
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
