import React from 'react';
import Divider from '@mui/material/Divider';
import colors from "../../utils/colors";
import { useNavigate } from 'react-router-dom';

const ProductDetails = ({name, image, size}) => {
  const navigate = useNavigate();

  return (
    <section className="text-gray-700 body-font overflow-hidden bg-white">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className='flex flex-col justify-center items-center py-20 h-max  lg:w-1/2 w-full position-absolute object-center rounded border border-gray-200'>
            {image.map((imageUrl, index) => (
              <div className="mb-2 mt-2" key={index}>
                <img alt='' src={imageUrl} style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            ))}
          </div>
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="text-sm title-font text-gray-500 tracking-widest">
              EuroOne
            </h2>
            <h1 className="text-gray-900 text-3xl title-font  mb-4" style={{ fontWeight: "400" }}>
              {name}
            </h1>
            <Divider className="my-4 bg-gray-800" />
            <div className='flex flex-col justify-center items-center'>
              {size.map((sizeUrl, index) => (
                <div className="leading-relaxed mb-10 mt-20" key={index}>
                  <img alt='' src={sizeUrl} style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              ))}
            </div>
            <Divider className="my-4" />
            <div className="flex justify-center mt-40">
              <button
                className="text-white bg-[#ff9000] border-0 py-2 px-6 w-7/12 focus:outline-none rounded transition-all duration-900 ease-in-out hover:scale-106 hover:w-10/12 hover:bg-[#ff9000]"
                style={{ backgroundColor: colors.primary }}
                onClick={() => navigate("./contactus")}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductDetails;
