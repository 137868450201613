import React from "react";
import styled from "styled-components";
import ProductCard from "./productCard";
import ppCompressionImg from "../../utils/PP.jpeg"
import hdpeImg from "../../utils/HDPE.jpeg"
import electro from ".././../utils/electrofusionLandingPage.png"
import tools from "../../utils/tools.jpg"
import colors from "../../utils/colors";
import jm from "../../utils/jm.webp"
import { Fade } from "react-awesome-reveal";
import { Link as ScrollLink } from 'react-scroll';
const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ProductCardWrapper = styled.div`
  width: 20%; /* Set each card to be 25% of the container width for larger screens */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  padding: 10px; /* Add some spacing around the cards */

  @media (max-width: 1200px) {
    width: 33.33%; /* On screens with a width of 1200px or smaller, set each card to 33.33% width for medium-sized screens */
  }

  @media (max-width: 768px) {
    width: 50%; /* On screens with a width of 768px or smaller, set each card to 50% width for smaller screens */
  }
`;

;



const Products = ({setSelectedPage,products,selectedProduct}) => {
  var img
  var title
  if (selectedProduct === "PPCompression") {
     img = ppCompressionImg;
     title="PP Compression  Fittings "
} else if (selectedProduct === "hdpe") {
    img = hdpeImg;
    title="HDPE Butt Fusion FIttings"
} else if (selectedProduct === "Electrofusion")
{
    title ="ElectroFusion Fittings"
    img = electro;
} else if (selectedProduct === "JointingMachines") {
    title="Jointing Machines"
    img = jm;
} else {
  title="Other Products "
    img = tools;
}





  return (

    <>
       <div className='w-screen h-screen  mb-4 p-2'>
      <div 
        className='flex justify-center items-center w-screen h-screen overflow-hidden' 
        style={{
          backgroundColor:colors.background,
          backgroundImage: `url(${img})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          color: '#fff',
        }}
        
      >
        <Fade direction='up' duration={2000}>
        <div className='flex-row justify-center items-center'>
          <h1 className='text-4xl '  style={{ fontSize: "3.5rem", textShadow: `2px 2px ${colors.primary}` }}>
              {title}
          </h1> 
  
        <div className='flex justify-center items-center mt-24'>
            <Fade direction='up' duration={5000}>
            <ScrollLink
  to="products" // Id of the next component
  spy={true}
  smooth={true}
  offset={0}
  duration={500}
>
  <button
    className="w-full sm:w-40 px-4 py-3 rounded text-md text-white border-2 "
    style={{
      color: "#fff",
      borderColor: colors.primary,
      backgroundColor: colors.primary,
      transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
      fontWeight:"500"
    }}
    onMouseEnter={(e) => (
      e.target.style.backgroundColor = "#fff",
      e.target.style.color = colors.primary,
      e.target.style.borderColor = colors.primary
    )}
    onMouseLeave={(e) => (      {
      name: "ELbow 90",
      image: "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2090%20.png?alt=media&token=c2aae16f-01ba-4643-be5a-4a4ae83141cd",
      sizeImage: [
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2090-1.png?alt=media&token=61abdf7f-750b-49c3-b66c-e45046d95102",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2090-2.png?alt=media&token=14b3cdb1-74e0-44c0-9461-fd83b403d58d",
      ]
    },
      e.target.style.backgroundColor = colors.primary,
      e.target.style.color = "#fff",
      e.target.style.borderColor = colors.primary
    )}
  >
    Learn More
  </button>
</ScrollLink>

          </Fade>
          </div>
        </div>
        </Fade>
      </div>
</div>
    <div className="" id="products">
      <ProductGrid>
        {products.products.map((product, index) => (
          <ProductCardWrapper key={index}>
            <ProductCard product={product} setSelectedPage={setSelectedPage} />
          </ProductCardWrapper>
        ))}
      </ProductGrid>
    </div>
    <br></br>    <br></br>

    </>
  );
};

export default Products;
