import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Showcase from './showcase';
import Showcase4 from './showcase4/showcase4';
import Showcase5 from './showcase5';
import Testimonial from './testimonial';
import colors from '../../utils/colors';
import CTA from './CTA';
import LandingPage from './landingPage';
import 'typeface-inter';

function Home() {
    useEffect(() => {
        // Ensure that ScrollTrigger is loaded and activated
        if (typeof window !== 'undefined') {
            gsap.registerPlugin(ScrollTrigger);
            // Animation for each component
            gsap.from('.landing-page', {
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: '.landing-page',
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none reverse'
                }
            });
            gsap.from('.showcase', {
                opacity: 0,
                y: 100,
                duration: 1,
                scrollTrigger: {
                    trigger: '.showcase',
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none reverse'
                }
            });
            gsap.from('.showcase4', {
                opacity: 0,
                y: 100,
                duration: 1,
                scrollTrigger: {
                    trigger: '.showcase4',
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none reverse'
                }
            });
            gsap.from('.showcase5', {
                opacity: 0,
                y: 100,
                duration: 1,
                scrollTrigger: {
                    trigger: '.showcase5',
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none reverse'
                }
            });
            gsap.from('.CTA', {
                opacity: 0,
                y: 100,
                duration: 1,
                scrollTrigger: {
                    trigger: '.CTA',
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none reverse'
                }
            });
            gsap.from('.testimonial', {
                opacity: 0,
                y: 100,
                duration: 1,
                scrollTrigger: {
                    trigger: '.testimonial',
                    start: 'top 80%',
                    end: 'bottom 20%',
                    toggleActions: 'play none none reverse'
                }
            });
        }
    }, []);

    const style = {
        backgroundColor: colors.background,
        color: colors.text,
        fontFamily: 'Inter, sans-serif',
    };

    return (
        <div style={style}>
            <div className="landing-page">
                <LandingPage></LandingPage>
            </div>
            <div className="showcase">
                <Showcase></Showcase>
            </div>
            <div className="showcase4">
                <Showcase4></Showcase4>
            </div>
            <div className="showcase5">
                <Showcase5></Showcase5>
            </div>
            <div className="CTA">
                <CTA></CTA>
            </div>
            {/* <div className="testimonial">
                <Testimonial></Testimonial>
            </div> */}
        </div>
    );
}

export default Home;
   