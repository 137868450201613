// colors.js

const colors = {
    primary: '#176B87', // Midnight Blue
    secondary: '#86B6F6', // Dodger Blue
    accent: '#27ae60', // Nephritis Green
    background: '#EEF5FF', // Platinum
    main:'#B4D4FF',
    darkMain:"#8a63a2",
    text: '#333', // Dark Gray
    heading: '#444', // Charcoal
    link: '#2980b9',
    white:"#ffffff", // Belize Hole Blue
    blue:"#29318F"
    
  };
  
  export default colors;
      