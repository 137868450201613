import React from 'react';
import './Cards.scss';
import HDPE from '../../../utils/HDPE.jpeg';
import jm from "../../../utils/jointingMachine.webp"
import PP from '../../../utils/PP.jpeg';
// import HDPEMachine from '../../../utils/HDPEMachine.jpeg';
import { Fade } from 'react-awesome-reveal';
import electro from "../../../utils/electrofusionLandingPage.png"


const cardData = [
  {
    image: PP,
    title: 'PP Compression Fittings',
    description: 'Compression fittings are suitable with all HDPE and PPRC pipes. Easy installation is the main feature of these fittings.',
    link:"ppcompressionfittings",

  },
  {
    image: HDPE,
    title: 'HDPE butt fusion Fittings',
    description: 'EuroOne Butt Fusion Fittings are compatible with all types of HDPE (PE-100) material',
    link:"hdpebuttfusionfittings",

  },
  {
    image: electro,
    title: 'Electro Fusion Fittings',
    link:"electrofusionfittings",
  },
  {
    image: jm,
    title: 'Jointing Machines',
    link:"jointingMachines",

  },


];

const Cards = () => {
  return (
    <div className='bg-transparent'>
      <div className="cards-wrapper items-center justify-center">
        <Fade>
        {cardData.map((card, index) => (
          <main>
          <div class = "card">
          <img src={card.image} alt=""/>
          <div class="card-content">
            <h2>  
              {card.title}
            </h2>
            <p className='m4-2'>
              {card.description}
            </p>

          </div>
        </div>
        </main>
        ))}
        </Fade>
      </div>
    </div>
  );
};

export default Cards;
