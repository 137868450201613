export const buttFusionFittings = {
    products: [
      {
        name: "End Cap",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20cap.png?alt=media&token=61197ac1-4131-457a-a06f-8de7614a4b01",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FCapDiagram.jpg?alt=media&token=e6765b05-c87c-437d-be65-53e8d24b8744"
        ],
        sizeImage: [
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fend%20cap%201.png?alt=media&token=60457d79-81ff-4008-87f2-71380035a96a",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fend%20cap%202.png?alt=media&token=50ba04b4-49b0-427c-8b33-4ac69ba0de6b",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fend%20cap%203.png?alt=media&token=65b3f0de-6636-4e85-87d4-b645061b72bc"
        ]
      },
      {
        name: "Elbow 45",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2045.png?alt=media&token=9917b3d7-5cc2-4a27-9fef-871b63ffebd9",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2F45elboowDiagram.jpg?alt=media&token=07701a4f-30c7-405c-9801-cb3d578108eb"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow45-1.png?alt=media&token=25b27872-ce57-4344-8dae-150bc9557521",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow45-2.png?alt=media&token=3198ec07-d426-458a-ab09-bd99c71463d6",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow45-3.png?alt=media&token=80954cbb-ddf7-4ada-9eac-04e811efcf3c"
        ]
      },
      {
        name: "Elbow 90",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2090%20.png?alt=media&token=c2aae16f-01ba-4643-be5a-4a4ae83141cd",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2F90ElbowDiagram.jpg?alt=media&token=7a14c1c0-a967-44df-a39e-231c52576738"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2090-1.png?alt=media&token=827e2da8-849b-4747-a61d-e34e837297d1",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow%2090-2.png?alt=media&token=200d2b9b-995f-433e-a80c-bf5ed21efbf1",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Felbow-90-3.png?alt=media&token=dd3ebda7-e47a-429a-b1b2-6ed560b5d9f9"
        ]
      },
      // {
      //   name: "Equal Tee",
      //   image: "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fequal%20tee.png?alt=media&token=e38d012b-4635-4d7e-93bf-7dacaa3367e9",
      //   sizeImage: [
      //       "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fequal%20tee%201.png?alt=media&token=28f0f0cd-a0fe-4a52-b24a-610ee1d08dc9",
      //       "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fequal%20tee%202.png?alt=media&token=97255e3a-3c62-47a0-a64e-4714dd0e1f9f",
      //   ]
      // },
      {
        name: "Reducer Tee",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20reducingTee.png?alt=media&token=951c0e72-c824-47d1-b37a-2cabbcbe382d",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FReducerTeeDiagram.jpg?alt=media&token=c495ba13-1966-49d3-b23c-bb16165111f0"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%20Tee%201.png?alt=media&token=0387fa36-68c0-4d89-a519-a7fac7f5a71b",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%20Tee%202.png?alt=media&token=fd60c9f2-92b4-4d6e-a4f0-5590421adaee",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%20Tee%203.png?alt=media&token=0c29059f-cbfc-49a2-ad64-cd6ddd73eb4d",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%20Tee%204.png?alt=media&token=32699d7b-3b70-4a54-a879-8deaf70a150a",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%20Tee%205.png?alt=media&token=1412b56e-89ec-4f5d-8966-1edaa9017642",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%20Tee%206.png?alt=media&token=f231ad9c-33b1-44f3-82ca-defffe2b4092"
        ]
      },
      {
        name: "Cross Fittings ",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fcross%20fitting.png?alt=media&token=22c99a0b-5b3d-434f-96a3-cadea6b9e058",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FEqualCrossDiagram.jpg?alt=media&token=076af981-eb6c-4541-b4af-9c351faee262"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fcross%20fitting%201.png?alt=media&token=6d2e28f2-f6b1-4c41-b744-db6db2693cab",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fcross%20fitting%202.png?alt=media&token=a3213a93-14d5-45dc-b6cf-4a0c64859b0a",
          ]
      },
      {
        name: "Reducer",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer.png?alt=media&token=37f97383-9ed8-4d15-9359-9eb54c46b74d",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FReducerDiagram.jpg?alt=media&token=1731a883-fbb7-4053-a18a-9e5a37fd8e3b"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%201.png?alt=media&token=b2ade546-ed52-4e8e-bb90-132fdd476a11",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%202.png?alt=media&token=8a7c66bf-f241-4044-88f8-70f77fcd80e5",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%203.png?alt=media&token=293be5b8-8d57-42c9-b297-e071baffdacc",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Freducer%204.png?alt=media&token=26c4ff5c-8a44-406a-9656-98ca2f7fa2ac",
          ]
      },
      {
        name: "Flange Adapter",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fflang%20adapter.png?alt=media&token=d4b6d800-cb17-4fee-a61c-1a5ddd129521",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FFlangAdapterDiagram.jpg?alt=media&token=fd0881e1-998c-4620-91d9-e28242ae66ca"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fflang1.png?alt=media&token=71092141-c14a-4723-831b-b08387bc9d4d",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fflang2.png?alt=media&token=3e0292e5-2f2a-44d9-9dde-87c6a7f9f05d",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fflang3.png?alt=media&token=0abdc268-9661-4c11-96c3-f411215035ab",
          ]
      },



      // add more products
    ]
  }

  export const ppCompressionFittings = {
    products: [
      {
        name: "90 Tee",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90Tee.png?alt=media&token=5ed47ec5-9d96-433f-bb19-e8ead402fb18"],
        sizeImage: [
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90%20Tee%20Size.png?alt=media&token=b0b91357-a6e6-47f8-990d-13aeb905a2fb",
        ]
      },
      {
        name: "Elbow 90",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90elbow.png?alt=media&token=61d661ce-64e3-4d5e-979f-80673606c174"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90ElbowSize.png?alt=media&token=3f978251-ecdb-434d-b458-c93fa0146903",
        ]
      },
      {
        name: "Elbow 90 Male ",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90elbowMale.png?alt=media&token=7befa9be-f602-43a2-9e54-a3e5a3b8afe5"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90FemaleElbowSize.png?alt=media&token=601477e0-43f1-4c07-8e4c-13adbaccef4d",
        ]
      },
      {
        name: "Male Tee",
        image:[ "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2FmaleTee.jpg?alt=media&token=4243c169-938d-40bd-bb80-6011a3335b78"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90MaleTeeSize.png?alt=media&token=d19a8baa-5c43-47ca-a5dd-93eeb022533a",
        ]
      },
      {
        name: "Elbow 90 Female",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90femaleelbow.png?alt=media&token=6a6852c3-ca7f-4c62-8231-36607c34bba5"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90FemaleElbowSize.png?alt=media&token=601477e0-43f1-4c07-8e4c-13adbaccef4d",
        ]
      },
      { 
        name: "Reducing Coupling ",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2FreducingCoupling.png?alt=media&token=01a53145-187d-4bd9-8f83-f87d8dfbfd97"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2F90ReducingTeeSize.png?alt=media&token=1ee4e9b1-f8e7-4e11-ba95-e5ae72e7bbd5",
        ]
      },
      {
        name: "End Plug ",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FengPlug.png?alt=media&token=ef89be53-dada-4e31-b5f4-57a247f7eea4"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2FendPlugSize.png?alt=media&token=bee9520e-ee1a-476e-be55-2fc169253358",
        ]
      },
      {
        name: "Coupling",
        image:[ "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2Fcoupling.png?alt=media&token=3efeab55-ab3a-4bfc-98de-a71ca65913af"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/PPCompressionFIttings%2FcouplingSize.png?alt=media&token=39871bc0-56ce-4de3-ab28-adc124c0423c",
        ]
      },
      // add more products
    ]
  }


  export const electroFusionFittings = {
    products: [
      {
        name: "Elbow 45 ",
        image:["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%2045elbow.png?alt=media&token=7325eb9f-2427-4591-a7b5-a11abfe67d5d",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2F45elbowDiagram.jpg?alt=media&token=e3dc0ee9-b1ab-4db2-9715-25f9d1456cfb"
        ],
        sizeImage: [
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Felbow45%20size.jpg?alt=media&token=48bf905f-28f5-4a40-8bea-47c306ea6c31",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433"
        ]
      },
      {
        name: "Elbow90",
        image:["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%2090%20elbow.png?alt=media&token=d6fce1b5-cb63-4aa0-adc7-c003406b603b",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2F90Elbow.jpg?alt=media&token=9445cbd4-3222-47ae-89f5-fbb7dfb022e7"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2F90%20epbow%20size.jpg?alt=media&token=6c37c955-1d95-46f2-909a-20419eae08f2",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
        ]
      },
      {
        name: "End Cap",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20cap.png?alt=media&token=61197ac1-4131-457a-a06f-8de7614a4b01",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FCap.jpg?alt=media&token=1a988d1f-45a3-483c-bc79-ef0725e09cd1"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Fcap%20size.jpg?alt=media&token=d00b3b28-6f0d-4d19-85f9-1982ad88eab5",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
        ]
      },
      {
        name: "Coupling",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20coupling.png?alt=media&token=a7ee9c6b-2d34-4cbd-b030-e97592d9b047",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FCouplerDiagram.jpg?alt=media&token=035f6ffc-05f2-46b8-9974-671825b2e865"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Fcoupling%20size%20.jpg?alt=media&token=dd553d68-2cea-4b79-8672-d3673a9c714d",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
        ]
      },
      {
        name: "Equal Tee",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20equalTee.png?alt=media&token=615f91cb-1013-484d-ab0c-50c9b20c8116",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FEqualTee.jpg?alt=media&token=d2491d15-51e7-4aed-8d38-51211a064be0"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
        ]
      },

      {
        name: "Flange",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20flang.png?alt=media&token=8731793c-d7b7-4425-a6cb-6fd3151766ec",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FFlang.jpg?alt=media&token=fbdda275-4b66-4034-9af1-50d9ad5be14b"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Fflang%20size.jpg?alt=media&token=1f94f6f0-9a0e-4f8c-8765-78eb48e37530",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
        ]
      },
      {
        name: "Flange Plate",
        image:[ "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20flangPlate.png?alt=media&token=4ff3cdb1-3821-449f-b262-c017255b7888",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FFlangePlateDiagram.jpg?alt=media&token=042e08bd-66c3-45ad-a114-5fdd6fe3929f"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Fflangplate%20size.jpg?alt=media&token=d17c0b7c-0aa5-451e-9134-735531728e15",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ButtFusionFittings%2FScreenshot%20from%202024-03-03%2015-16-14.png?alt=media&token=b570a154-f2a9-48d4-8952-e031476a7433",
        ]
      },
      {
        name: "Reducer",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20reducer.png?alt=media&token=632a7793-8e04-4a6d-b384-0ad8949618ba",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FReducer.jpg?alt=media&token=e4ee5528-f31f-4062-a7be-942e7e30b673"
        ],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Freducersize1.jpg?alt=media&token=1ea73101-7ead-4f33-a831-11418266067d",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Freducersize2.jpg?alt=media&token=bba52dd6-67e7-4318-bfdb-c7db55d10f1f",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Freducersize3.jpg?alt=media&token=8e0d2a12-727b-4080-bea1-5aee7c9bb26a",
        ]
      },
      {
        name: "Reducer Tee",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FelectroFusion%20reducingTee.png?alt=media&token=951c0e72-c824-47d1-b37a-2cabbcbe382d",
        "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2FreducingTeeDiagram.jpg?alt=media&token=77115230-cd07-4e45-a89c-cd800230ecd8"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Freducingtee%20size1.jpg?alt=media&token=c5788a04-4227-472a-9078-7488affe4bc2",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Freducingtee%20size2.jpg?alt=media&token=100da516-77f5-467c-8f48-08354425ce00",
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/ElectroFusionFittings%2Freducingteesize3.jpg?alt=media&token=4153e2af-1ec0-497d-8b2d-da699c5299f4",
        ]
      },
      // add more products
    ]
  }

  export const JointingMachines = {
    products: [
      {
        name: "Butt Fusion Welding Machines",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FhydrolicButtFusionWeldingMachine400%20To%201200.jpg?alt=media&token=930b1a42-45e8-45ea-befb-f0cd97cd22d7"],
        sizeImage: [
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FhydrolicButtFusionWeldingMachineSUD1600%20TO%20SUD2500.jpg?alt=media&token=d9fab1ca-2d17-440b-bc93-a548d21d9755",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FhydrolicButtFusionWeldingMachineSUD160HSUD200HSUD250H.jpg?alt=media&token=5174d18f-dfa7-4369-946e-712c2de670ed",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FSUD1600%20TO%20SUD2500%20MODEL.jpg?alt=media&token=d0c06494-52f4-413d-9582-233b85e8c10d",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FSUD160_200_250%20MLmodel.jpg?alt=media&token=09828aa6-0cdb-4d31-84e5-0e50b827e47933",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FSUD315H_SUD335H%20MODEL.jpg?alt=media&token=88d7d664-6be3-404f-9c7d-de9be00d60fc",
          "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FSUD400%20TO%20SUD1200%20MODEL.jpg?alt=media&token=97bed5d8-9d91-429b-a708-5efffcc4be3a",

        ]
      },
      {
        name: "Electrofusion  Welding Machine ",
        image: ["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FelectroFusion_WeldingMachineSDE250B_TO_SDE_1000B-transformed.jpeg?alt=media&token=d63169dc-4a45-4832-87ae-2b864d79856f"],
        sizeImage: [
            "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/JointingMachines%2FSDE250B%20TO%20SDE1000B.jpg?alt=media&token=b6152ec5-b355-4d48-a598-919f13f7cc6e",
        ]
      },
     
      // add more products
    ]
  }

  export const otherProducts = {
    products: [
      {
        name: "Branch Saddle Cutter ",
        image:[ "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FbranchSaddleCutter.jpg?alt=media&token=6fd3b80c-c12e-4c82-b886-6c798f241679"],
        sizeImage: [
          
        ]
      },
      // {
      //   name: "Butt Fusion Machinery",
      //   image: "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FbuttFusionMachinery.jpg?alt=media&token=2c0b2430-c3e8-4d39-8278-95f62959e680",
      //   sizeImage: [
      //   ]
      // // },
      // {
      //   name: "Electro Fusion Machinery",
      //   image: "https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FelectroFusionMachinry.jpg?alt=media&token=63f4a331-6cb0-47dd-9193-52c9808499d2",
      //   sizeImage: [

      //   ]
      // },
      {
        name:"Pipe Cutter",
        image:["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FpipeCutter.jpg?alt=media&token=9f741611-a72a-4b83-bc11-bba9b5114814"],
        sizeImage:{

        }
      },
      {
        name:"Pipe Scraper",
        image:["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FpipeScraper.jpg?alt=media&token=42d0a353-8342-4fcf-86ea-440a3c4a8306"],
        sizeImage:[

        ]
      },
      {
        name:"Rotating Cutter",
        image:["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FrotatingCutter.jpg?alt=media&token=dac0b91f-7523-4901-a8a9-b2c05939ff2d"],
        sizeImage:[

        ]
      },
      {
        name:"Socket Welder",
        image:["https://firebasestorage.googleapis.com/v0/b/euroone-af000.appspot.com/o/Tools%2FsocketWelder.jpg?alt=media&token=a8720e60-4dde-4bdc-9ce4-7051a54aad9f"],
        sizeImage:[

        ]
      }
    ]
  }