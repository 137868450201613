import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import img from "../../utils/water.png"
import colors from "../../utils/colors";



function Showcase() {
  return (
    <div class="md:flex flex-row  bg-transparent " id="showcase"  >
      <div class="w-full md:w-8/12 mb-10">
        <div class="container mx-auto h-full sm:p-10">
          <nav class="flex px-4 justify-between items-center">
            <div class="text-4xl  px-2">
              Euro<span class="" style={{color:colors.primary}}>One.</span>
            </div>

          </nav>
          <header class="container px-4 lg:flex mt-10 items-center h-full lg:mt-0">
            <div class="w-full">
              <h1 class="text-4xl lg:text-6xl  px-2" style={{ fontWeight: "600" }}>
                Choose Euro One
              </h1>
              <div class="w-20 h-2 bg-[#176b87] my-4 ml-2.5"></div>
              <p class="text-xl mb-6 px-2">
                Euro One is the manufacturer of HDPE Butt fusion and PP compression fittings used for water supply applications. Our wide range of products includes Tees, Elbows, Reducing Tees, Reducers, Flanges, Adapters, and more, all manufactured under strict compliance with ISO-4427. All our products are warranted against any working failure and are available in different pressure ratings.
              </p>
              <p class="text-xl mb-6 px-2" style={{ color: colors.secondary, fontWeight: "600" }}>
  A&H International holds the sole distribution rights and is the importer of the complete range of Euro One products in Pakistan. We are dedicated to providing high-quality, reliable fittings to meet your water supply needs.
</p>
              <button
            className="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md text-white border-2 "
            style={{
              color: "#fff",
              borderColor: colors.primary,
              backgroundColor: colors.primary,
              transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
            }}
            onMouseEnter={(e) => (
              e.target.style.backgroundColor = "#fff",
              e.target.style.color = colors.primary,
              e.target.style.borderColor = colors.primary
            )}
            onMouseLeave={(e) => (
              e.target.style.backgroundColor = colors.primary,
              e.target.style.color = "#fff",
              e.target.style.borderColor = colors.primary
            )}
          >
            Learn More
          </button>
            </div>
          </header>
        </div>
      </div>

      {/* <Fade direction="right" triggerOnce> */}
        <div className="  md:h-4/12 w-full md:w-4/12 " style={{boxShadow:`2px 2px ${colors.primary}`}}>
          <Fade direction="right">

        <img
          src={img}
          alt="Leafs"
          className="lg:w-full object-cover  h-full  w-screen transition duration-300 ease-in-out hover:scale-110 "
          />
          </Fade>
        </div>
      {/* </Fade> */}

    </div>
  );
}

export default Showcase;